import React from "react";
import { getJson } from "../config/config";
import { Tooltip } from "./Tooltip";
import { getImage } from "../config/firebaseConfig";
import { HashLink as Link } from 'react-router-hash-link';


export default function NotFound({ darkTheme, setDarkTheme }) {
  const changeTheme = () => {
    console.log("DARK THEME: ", darkTheme);
    setDarkTheme(!darkTheme);
    console.log("DARK THEME: ", darkTheme);
  };

  return (
    <>
      <header className=" px-2 sticky-top-slide d-flex flex-wrap align-items-center justify-content-center justify-content-md-between bg-light">
        <Link
          to="/"
          className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none"
        >
          <img
            src={getImage(getJson("ghmcTextLogo"))}
            alt="GHMC Logo"
            style={{
              width: "auto",
              maxHeight: "60px",
              height: "auto",
            }}
          />
        </Link>

        <div className="col-auto col-lg-2 d-flex justify-content-end">
          <ul className="social-icons social-icons-dark mode">
            <li className="switch-btn">
              <Tooltip
                text={darkTheme ? "Dark Mode" : "Light Mode"}
                placement="top"
              >
                <a onClick={changeTheme}>
                  <i
                    className={
                      darkTheme ? "fa-solid fa-moon" : "fa-solid fa-sun"
                    }
                  />
                </a>
              </Tooltip>
            </li>
            {getJson("twitterUrl") && (
              <li className="social-icons-twitter social-tab">
                <Tooltip text="Twitter" placement="top">
                  <a
                    href={getJson("twitterUrl")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter" />
                  </a>
                </Tooltip>
              </li>
            )}
            {getJson("facebookUrl") && (
              <li className="social-icons-facebook">
                <Tooltip text="Facebook" placement="top">
                  <a
                    href={getJson("facebookUrl")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                </Tooltip>
              </li>
            )}
            {getJson("instagramUrl") && (
              <li className="social-icons-instagram">
                <Tooltip text="Instagram" placement="top">
                  <a
                    href={getJson("instagramUrl")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </Tooltip>
              </li>
            )}
          </ul>
        </div>
      </header>

      <main
        className={`d-flex flex-column justify-content-center align-items-center text-center vh-100 overflow-hidden ${
          darkTheme ? "bg-dark-1 text-white" : ""
        }`}
        style={{ margin: 0, padding: 0 }}
      >
        <h1 className={"display-5 fw-bold text-emphasis "+ darkTheme?"text-white":""}>Page Not Found</h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">
            Oops! The page you're looking for doesn't exist. It might have been
            removed, renamed, or did not exist in the first place.
          </p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <Link to="/" className="btn btn-primary btn-lg px-4 gap-3">
              Go to Homepage
            </Link>
            <Link to="/#schedule" className="btn btn-primary btn-lg px-4 gap-3">
              Go to Schedule
            </Link>
          </div>
          
        </div>
      </main>
    </>
  );
}
